
import Api from "@/assets/api/api";
import { FormInstance, ElMessage } from "element-plus";
import { useStore } from "vuex";
import { Avatar, Lock } from "@element-plus/icons-vue";

import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "",
  setup() {
    let router = useRouter();
    const store = useStore();
    const validateUser = (rule: any, value: any, callback: any) => {
      let reg =
        /(^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$)|(^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$)/;
      if (!reg.test(data.ruleForm.account)) {
        callback(new Error("请输入正确的手机号/邮箱"));
      } else {
        callback();
      }
    };
    const data = reactive({
      Avatar: Avatar,
      Lock: Lock,
      checked: false,
      ruleForm: <any>{
        account: "",
        password: "",
        loginType: "PWD",
      },
      rules: {
        account: [
          {
            required: true,
            validator: validateUser,
            trigger: ["blur", "change"],
          },
        ],
        // account: [
        //   {
        //     required: true,
        //     message: "请输入正确账号",
        //     trigger: "blur",
        //   },
        // ],
        password: [
          {
            required: true,
            message: "请输入正确密码",
            trigger: "blur",
          },
        ],
      },
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    const ruleFormRef = ref();
    const getLogin = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid: any, fields: any) => {
        if (valid) {
          Api.commonPost(
            "User",
            "Login",
            data.ruleForm,
            (res: any) => {
              let {
                data: { token, userProjectPermissionDtos, companyId },
              } = res;
              store.commit("SET_TOKEN", token);
              store.commit("SET_companyId", companyId);
              if (userProjectPermissionDtos.length <= 0) {
                ElMessage.error("该用户没有权限访问");
                return;
              }

              store.commit(
                "SET_permissionId",
                userProjectPermissionDtos[0].permissionId
              );

              ElMessage({
                message: "登录成功",
                type: "success",
              });
              let path = "/AutoMonitor";
              // let path = "/Operate";
              if (userProjectPermissionDtos[0].permissionId == 3) {
                path = "/Operate";
              }
              router.push({
                path,
              });
            },
            (err: any) => {
              console.log(err);
              ElMessage.error(err.msg);
            }
          );
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const goBack = () => {
      router.back();
    };
    return {
      ...toRefs(data),
      goBack,
      resetForm,
      getLogin,
      ruleFormRef,
      validateUser,
    };
  },
});
